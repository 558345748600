<template>
   <div class="obi-404">
      <div class="obi-404-body">
         <IconIntegration404 v-if="icon === 'integration'" />
         <IconVisitor404 v-else-if="icon === 'visitor'" />
         <IconDevice404 v-else-if="icon === 'devices'" />
         <Icon404 v-else />
         <h1 class="obi-404-title">
            {{ title }}
         </h1>
         <div class="obi-404-desc">
            {{ desc }}
         </div>
         <div class="obi-404-actions" v-if="$slots.actions">
            <slot name="actions" />
         </div>
      </div>
   </div>
</template>

<script>
import Icon404 from '@/components/Icons/general/Icon404';
import IconDevice404 from '@/components/Icons/devices/IconDevice404';
import IconVisitor404 from '@/components/Icons/visitors/IconVisitor404';
import IconIntegration404 from '@/components/Icons/integrations/IconIntegration404';

export default {
   name: 'Obi404',
   components: {
      Icon404,
      IconDevice404,
      IconVisitor404,
      IconIntegration404,
   },
   props: {
      icon: {
         type: String,
      },
      title: {
         type: String,
         default: 'Kayıt Bulunamadı',
      },
      desc: {
         type: String,
         default: ' Listelenecek bir kayıt bulunamadı. Kayıt ekleyerek bu listede görüntüleyebilirsiniz',
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.obi-404 {
   width: 100%;
   display: flex;
   padding: 100px;
   border-radius: 3px;
   align-items: center;
   justify-content: center;

   .obi-404-title {
      font-size: 20px;
      color: $primary;
      margin-top: 20px;
      font-weight: 300;
      margin-bottom: 14px;
   }

   .obi-404-body {
      text-align: center;
   }

   .obi-404-actions {
      display: grid;
      grid-gap: 1rem;
      margin-top: 3.5rem;
      align-items: center;
      grid-auto-flow: column;
      justify-content: center;
   }

   .obi-404-desc {
      font-size: 13px;
   }

   .obi-404-body {
      i {
         color: #1a54c6;
         font-size: 48px;
      }
   }
}
</style>
